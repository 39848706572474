import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Div from "../Div";
import "./servicelist.scss";
import { useServiceContentful } from "../../useContentful";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modal from "react-modal";

export default function ServiceList({ category }) {
  const [active, setActive] = useState(0);
  const handelActive = (index) => {
    setActive(index);
  };

  const [serviceData, setServiceData] = useState([]);
  const { getServiceData } = useServiceContentful();

  useEffect(() => {
    if (serviceData.length === 0) {
      getServiceData().then((data) => {
        setServiceData(data);
      });
    }
  }, [getServiceData, serviceData.length]);

  const sortedItems = serviceData.reduce((sorted, item) => {
    if (!sorted[item.category]) {
      sorted[item.category] = [];
    }
    sorted[item.category].push(item);
    return sorted;
  }, {});

  const [selectedItem, setSelectedItem] = useState(null);

  const [modelIsOpen, setmodelIsOpen] = useState(false);

  const openItemModel = (item) => {
    setSelectedItem(item);
    setmodelIsOpen(true);
  };

  const closeItemModel = () => {
    setSelectedItem(null);
    setmodelIsOpen(false);
  };

  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 9999,
    },
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      padding: "0",
      transform: "translate(-50%, -50%)",
      border: "none",
      background: "none",
      overflow: "visible",
      WebkitOverflowScrolling: "touch",
    },
  };

  return (
    <Div className="cs-iconbox_3_list">
      {sortedItems[category]?.map((item, index) => {
        return (
          <>
            {modelIsOpen && selectedItem === item?.title && (
              <Modal
                isOpen={true}
                onRequestClose={closeItemModel}
                contentLabel="User Modal"
                style={modalStyle}
                ariaHideApp={false}
              >
                <Carousel
                  showArrows={true}
                  showThumbs={false}
                  showStatus={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={5000}
                  transitionTime={500}
                  swipeable={true}
                  emulateTouch={true}
                  dynamicHeight={true}
                  showIndicators={false}
                >
                  {item?.url?.map((content, index) => {
                    return (
                      <>
                        <Div className="">
                          <Div key={index} className="">
                            {content.includes("youtube") ? (
                              <iframe
                                src={content}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                width={1080}
                                height={600}
                              ></iframe>
                            ) : (
                              <img src={content} alt="img" />
                            )}
                          </Div>
                        </Div>
                      </>
                    );
                  })}
                </Carousel>
              </Modal>
            )}
            <Div
              className={`cs-hover_tab ${active === index ? "active" : ""}`}
              key={index}
              onMouseEnter={() => handelActive(index)}
            >
              <Link
                className="cs-iconbox cs-style3"
                onClick={() => openItemModel(item?.title)}
              >
                <>
                  <Div className="cs-image_layer cs-size_md">
                    <Div className="cs-image_layer_in">
                      <img
                        src={item.image}
                        alt="Thumb"
                        className="w-100 cs-radius_15"
                      />
                    </Div>
                  </Div>
                  <span className="cs-iconbox_icon cs-center">
                    <svg
                      width={30}
                      height={29}
                      viewBox="0 0 30 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M29.3803 3.05172C29.4089 1.94752 28.537 1.02921 27.4328 1.00062L9.43879 0.534749C8.33459 0.506159 7.41628 1.37811 7.38769 2.48231C7.35911 3.58651 8.23106 4.50482 9.33526 4.53341L25.3299 4.94752L24.9158 20.9422C24.8872 22.0464 25.7592 22.9647 26.8634 22.9933C27.9676 23.0218 28.8859 22.1499 28.9144 21.0457L29.3803 3.05172ZM3.37714 28.5502L28.7581 4.4503L26.0039 1.54961L0.622863 25.6495L3.37714 28.5502Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <Div className="cs-iconbox_in">
                    <h2 className="cs-iconbox_title">{item.title}</h2>
                    <Div className="cs-iconbox_subtitle">{item.subTitle}</Div>
                  </Div>
                </>
              </Link>
            </Div>
          </>
        );
      })}
    </Div>
  );
}
