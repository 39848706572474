import React , { useState } from 'react'
import Div from '../Div'

export default function Newsletter({title, subtitle, placeholder}) {
  const [subscribEmail, setSubscribeEmail] = React.useState({
    email: '',
  })

  const postSubscribeEmail = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSubscribeEmail({ ...subscribEmail, [name]: value });
  }

  const submitClick = async (event) => {
    event.preventDefault();
    const { email } = subscribEmail;

    if (email) {
      const res = await fetch('https://tlr-edits-default-rtdb.firebaseio.com/SubcribedEmail.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: subscribEmail.email,
        }),
      });

      if (res) {
        setSubscribeEmail({
          email: '',
        });
        alert('Data Stored');
      }
    }

    else {
      alert('Please fill the data');
    }
  }

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form action="#" className="cs-newsletter_form">
          <input type="email" className="cs-newsletter_input" placeholder={placeholder}  value={subscribEmail.email} name='email' onChange={postSubscribeEmail}/>
          <button className="cs-newsletter_btn" onClick={submitClick}><span>Send</span></button>
        </form>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  )
}