import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import parse from 'html-react-parser';

export default function PageHeading({ title, bgSrc, pageLinkText, subtitle }) {
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg"
      style={{ backgroundImage: `url(${bgSrc})` }}
    >
      <Div className="container">
        <Div className="cs-page_heading_in">
          <h1 className="cs-page_title cs-font_50 cs-white_color">{title}</h1>      
        </Div>
      </Div>
    </Div>
  );
}
