import React from 'react'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget(instagram) {
  return (
    <Div className="cs-social_btns cs-style1">
      <a href='https://www.linkedin.com/company/thelectureroom/' className="cs-center" target='_blank' rel="noreferrer">
        <Icon icon="fa6-brands:linkedin-in" />
      </a>
      <a href='https://www.facebook.com/thelectureroomjodhpur' className="cs-center" target='_blank' rel="noreferrer">
        <Icon icon="fa6-brands:facebook" />               
      </a>
      <a href='https://www.instagram.com/the_lecture_room/' className="cs-center" target='_blank' rel="noreferrer">
        <Icon icon="fa6-brands:instagram" />              
      </a>
      <a href='https://www.youtube.com/@thelectureroom9195' className="cs-center" target='_blank' rel="noreferrer">
        <Icon icon="fa6-brands:youtube" />
      </a>
    </Div>
  )
}
