import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Spacing from "../Spacing";
import { useBlogContentful } from "../../useContentful";

export default function BlogDetailsPage() {
  const [blogs, setBlogs] = useState([]);
  const { getBlogData } = useBlogContentful();

  useEffect(() => {
    if (blogs.length === 0) {
      getBlogData().then((data) => {
        setBlogs(data);
      });
    }
  }, [getBlogData, blogs.length]);


  const params = useParams();
  pageTitle("Blog Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const blog = blogs[params.blogDetailsId] || {};

  return (
    <>
      {/* Start Page Heading Section */}
      {/* <PageHeading
        title="Community"
        bgSrc="/images/blog_details_hero_bg.jpeg"
        pageLinkText="Blog"
      /> */}
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-10">
            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img
                  src={blog.image}
                  alt="Post"
                  className="w-100 cs-radius_15"
                />
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">{blog.date}</span>
                  <Link to="/blog" className="cs-post_avatar">
                    {blog.subject}
                  </Link>
                  <span className="cs-posted_by">{blog.writtenBy}</span>
                </Div>
                <Div className="cs-post_content">
                  <h2 className="cs-post_title cs-mb-0">{blog.heading1}</h2>
                </Div>
                <Div className="cs-post_content">
                  <p className="cs-mb-0">{blog.desc1}</p>
                </Div>
                <blockquote className="cs-primary_font">
                  {blog.blackquote}
                  <small>{blog.quoteby}</small>
                </blockquote>

                <Div className="cs-post_content">
                  <h2 className="cs-post_title cs-mb-0">{blog.heading2}</h2>
                </Div>

                <Div className="cs-post_content">
                  <p className="cs-mb-0">{blog.desc2}</p>
                </Div>

                <Div className="row">
                  <Div className="col-md-6">
                    <img
                      src={blog.image1}
                      alt="Blog Details"
                      className="cs-radius_15 w-100"
                    />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                  <Div className="col-md-6">
                    <img
                      src={blog.image2}
                      alt="Blog Details"
                      className="cs-radius_15 w-100"
                    />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                </Div>
              </Div>
            </Div>
            {/* End Details Post Content */}

            {/* Start Comment Section */}
            {/* <Spacing lg="30" md="30" />
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg="5" md="5" />
            <p className="cs-m0">
              Your email address will not be published. Required fields are
              marked *
            </p>
            <Spacing lg="40" md="30" />
            <form className="row">
              <Div className="col-lg-6">
                <label>Full Name*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div
                  data-lastpass-icon-root="true"
                  style={{
                    position: "relative !important",
                    height: "0px !important",
                    width: "0px !important",
                    float: "left !important",
                  }}
                />
              </Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Website*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea cols={30} rows={7} className="cs-form_field" />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form> */}
            {/* End Comment Section */}
          </Div>
        </Div>
      </Div>

      {/* Start CTA Section */}

      <Spacing lg="150" md="80" />

      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
