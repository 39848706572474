import React, { useState , useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TeamSlider from "../Slider/TeamSlider";
import Spacing from "../Spacing";
import {
  useAboutContentful,
  useAboutHeroContentful,
} from "../../useContentful";
import Hero3 from "../Hero/Hero3";
import TestimonialSlider from "../Slider/TestimonialSlider";

export default function AboutPage() {
  const [aboutData, setAboutData] = useState([]);
  const [aboutHero, setAboutHero] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { getAboutHeroData } = useAboutHeroContentful();
  const { getAboutData } = useAboutContentful();

  if (aboutData.length === 0) {
    getAboutData().then((data) => {
      setAboutData(data);
    });
  }

  if (aboutHero.length === 0) {
    getAboutHeroData().then((data) => {
      setAboutHero(data);
    });
  }

  pageTitle("About");

  return (
    <>
      {
        aboutHero?.map((item, index) => (
          <Hero3
            key={index}
            title={item.title}
            socialLinksHeading="Follow Us"
            bgImageUrl={item.url}
          />
        ))}

      <Div className="container">
        <Cta
          title="Since, We started TLR in 2019. Whether you’re a startup or an enterprise, We’ve got a package that’s right for you."
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/slider_4.jpeg"
        />
      </Div>

      {/* Start Page Heading Section */}
      {/* <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      /> */}
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="100" md="80" />
      {
        aboutData.map((item, index) => (
          <Div className="container" key={index}>
            <Div className="row">
              <Div className="col-xl-7   col-lg-7">
                <SectionHeading title={item.title} subtitle="About Our Company">
                  <Spacing lg="10" md="20" />
                  <p className="">{item.content}</p>
                  <Spacing lg="10" md="30" />
                  <Div className="cs-separator cs-accent_bg"></Div>
                  <Spacing lg="25" md="40" />
                </SectionHeading>
              </Div>
              <Div className="col-lg-5 ">
                <img
                  src={item.image2}
                  alt="About"
                  className="w-100 cs-radius_15"
                />
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-lg-7">
                <img
                  src={item.image3}
                  alt="About"
                  className="w-100 cs-radius_15"
                />
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-lg-5">
                <img
                  src={item.image4}
                  alt="About"
                  className="w-100 cs-radius_15"
                />
                <Spacing lg="25" md="25" />
              </Div>
            </Div>
          </Div>
        ))}
      {/* End About Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="80" md="80" />
      {aboutData &&
        aboutData.map((item, index) => (
          <Div className="container" key={index}>
            <Div className="row">
              <Div className="col-xl-5 col-lg-6">
              <Spacing lg="80" md="" />
                <Div className="cs-image_layer cs-style1">
                  
                  <Div className="cs-image_layer_in">
                    
                    <img
                      src={item.image}
                      alt="About"
                      className="w-100 cs-radius_15"
                    />
                  </Div>
                </Div>
                <Spacing lg="0" md="40" />
              </Div>
              <Div className="col-xl-5 offset-xl-1 col-lg-6">
                <SectionHeading title={item.title2} subtitle="">
                  <Spacing lg="10" md="10" />
                  <p className="cs-m0">{item.mission}</p>
                  <Spacing lg="15" md="10" />
                  <Div className="cs-separator cs-accent_bg"></Div>
                </SectionHeading>
                <SectionHeading title={`Vision`} subtitle="">
                  <Spacing lg="15" md="10" />
                  <p className="cs-m0">{item.vision}</p>
                  <Spacing lg="10" md="10" />
                  <Div className="cs-separator cs-accent_bg"></Div>
                </SectionHeading>
              </Div>
            </Div>
          </Div>
        ))}
      {/* End Why Choose Section */}

      {/* <Spacing lg="150" md="80" />

      <Div className="container">
        <Div className="row">
          <SectionHeading title="Workflow" subtitle="Our Process" />
          <Spacing lg="30" md="20" />
          <p className="cs-m0">
            We follow a simple process to deliver the best results for our
            clients.
          </p>
          <Spacing lg="30" md="30" />
          <Div className="col-lg-6">
            <Portfolio
              src="/images/portfolio_2.jpeg"
              title="Design"
              subtitle="We design the best solutions for your business."
              variant="cs-style1 cs-type1"
            />
            <Spacing lg="30" md="30" />
          </Div>

          <Div className="col-lg-6">
            <Portfolio
              src="/images/portfolio_2.jpeg"
              title="Design"
              subtitle="We design the best solutions for your business."
              variant="cs-style1 cs-type1"
            />
          </Div>
        </Div>
      </Div> */}

      {/* Start Team Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="A team of Innovators and Storytellers<br /> to lead your creative needs"
          subtitle="Meet Our Creative Catalysts"
          variant="cs-style1"
        />
        <Spacing lg="50" md="45" />
        <TeamSlider />
      </Div>


      {/* End Team Section */}

      <Spacing lg="150" md="80" />
      <TestimonialSlider />

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s Collaborate,<br /> <i>Innovate</i> and, Create"
          btnText="Get in Touch and let's craft something extraordinary."
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
