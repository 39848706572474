import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import Div from '../Div'
import './post.scss'

export default function PostStyle2({ blog , href }) {

 
  return (
    <Div className="cs-post cs-style2">
      <Link to={href} className="cs-post_thumb cs-radius_15">
        <img src={blog.image} alt="Post" className="w-100 cs-radius_15" />
      </Link>
      <Div className="cs-post_info">
        <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
          <span className="cs-posted_by">{blog.date}</span>
          <Link to={href} className="cs-post_avatar">{blog.subject}</Link>
        </Div>
        <h2 className="cs-post_title"><Link to={href}>{blog.title}</Link></h2>
         {/* <Div className="cs-post_sub_title">{documentToReactComponents(blog.content)}</Div>  */}
        <Button btnLink={href} btnText='See More' />
      </Div>
    </Div>
  )
}
