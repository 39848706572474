import React, { useState, useEffect } from 'react';
import Div from '../Div';
import Team from '../Team';
import Spacing from '../Spacing';
import { useTeamMemberContentful } from '../../useContentful';

export default function TeamSlider() {

  const [teamData, setTeamData] = useState([]);

  const { getTeamMemberData } = useTeamMemberContentful();

  useEffect(() => {
    if (teamData.length === 0) {
      getTeamMemberData().then((data) => {
        setTeamData(data);
      });
    }
  }, [getTeamMemberData, teamData.length]);

  return (
    <Div className="container">
      <Div className="row">

        {teamData.map((item, teamDetailsId) => (
          <Div key={teamDetailsId} className="col-lg-3">
            <Team
              memberImage={item.memberImage}
              memberName={item.memberName}
              memberDesignation={item.memberDesignation}
              linkedIn={item.linkedIn}
              instagram={item.instagram}
              teamIndex={teamDetailsId}
            />
            <Spacing md='30' lg='30' />
          </Div>
        ))}
      </Div>
    </Div>
  );
}
