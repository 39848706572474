import React, {useState , useEffect} from "react";
import Slider from "react-slick";
import Div from "../Div";
import Post from "../Post";
import { useBrandLogoContentful } from "../../useContentful";


export default function PostSlider() {



  const [partnerLogos, setPartnerLogos] = useState([]);

  const { getBrandLogoData } = useBrandLogoContentful();

  useEffect(() => {
    if (partnerLogos.length === 0) {
      getBrandLogoData().then((data) => data && setPartnerLogos(data));
    }
  }, [getBrandLogoData, partnerLogos]);




  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24">
      {partnerLogos.map((item, index) => (
        <Div key={index}>
          <Post
            url={item.url}
            src={item.image}
            alt={item.title}
            date={item.shortInfo}
            title={item.title}
          />
        </Div>
      ))}
    </Slider>
  );
}
