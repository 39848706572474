import React from "react";
import { useState } from "react";
import PricingTable from ".";
import Section from "../Div";
import Spacing from "../Spacing";
import { BuildYourBrand, EditRating } from "./data";

export default function PricingTableList() {
  const [tab, setTab] = useState("Edit Rating");
  return (
    <Section className="position-relative">
      <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
        <li
          className={tab === "Edit Rating" ? "active" : ""}
          onClick={() => setTab("Edit Rating")}
        >
          Edit Rating
        </li>
        <li
          className={tab === "Build Your Brand" ? "active" : ""}
          onClick={() => setTab("Build Your Brand")}
        >
          Build Your Brand
        </li>
      </ul>
      <Section className="row">
        <Section className="col-lg-4">
          {tab === "Edit Rating" && (
            <PricingTable
              title="Basic"
              price="1500"
              currency="₹"
              timeline="per min"
              features={["2 revisions", "1 meet", "48 Hours Turnaround Time"]}
              btnText="Purchase Now"
              btnLink="/contact"
            />
          )}
          {tab === "Build Your Brand" && (
            <PricingTable
              title="Bronze"
              price="20k-30k"
              currency="₹"
              timeline="monthly"
              features={["10 reels", "10 mins of output", "1 meet"]}
              btnText="Purchase Now"
              btnLink="/contact"
            />
          )}

          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          {tab === "Edit Rating" && (
            <PricingTable
              title="Shorts"
              price="2500"
              currency="₹"
              timeline="per short"
              features={["3 revisions", "1 meet", "24 Hours Turnaround Time"]}
              btnText="Purchase Now"
              btnLink="/contact"
            />
          )}
          {tab === "Build Your Brand" && (
            <PricingTable
              title="Silver"
              price="50k-60k"
              currency="₹"
              timeline="monthly"
              features={["25 reels", "25 mins of output", "3 meets"]}
              btnText="Purchase Now"
              btnLink="/contact"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          {tab === "Edit Rating" && (
            <PricingTable
              title="Podcast"
              price="15k"
              currency="₹"
              timeline="40 min"
              features={[
                "500 per minute after 40 minutes",
                "2 revisions",
                "1 meet",
                "48 Hours Turnaround Time",
                "15k for upto 60 minutes(Recurring)",
              ]}
              btnText="Purchase Now"
              btnLink="/contact"
            />
          )}
          {tab === "Build Your Brand" && (
            <PricingTable
              title="Gold"
              price="1 Lakh"
              currency="₹"
              timeline="monthly"
              features={[
                "30 reels",
                "30/60 min long format video",
                "Anytime support and, Head of Content",
              ]}
              btnText="Purchase Now"
              btnLink="/contact"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
      </Section>
    </Section>
  );
}
