import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import BlogPage from "./components/Pages/BlogPage";
import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
import ServicesPage from "./components/Pages/ServicesPage";
import TeamPage from "./components/Pages/TeamPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import TeamDetails from "./components/Pages/TeamDetails";
import PhotographyAgencyHome from "./components/Pages/PhotographyAgencyHome";
import CreativePortfolioHome from "./components/Pages/CreativePortfolioHome";
import DigitalAgencyHome from "./components/Pages/DigitalAgencyHome";
import MarketingAgencyHome from "./components/Pages/MarketingAgencyHome";
import ShowcasePortfolioHome from "./components/Pages/ShowcasePortfolioHome";
import CaseStudyShowcaseHome from "./components/Pages/CaseStudyShowcaseHome";
import Layout from "./components/Layout";
import CaseStudyDetailsPage from "./components/Pages/CaseStudyDetailsPage";
import FaqPage from "./components/Pages/FaqPage";
import Home from "./components/Pages/Home";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route exact index element={<ShowcasePortfolioHome />} />
          {/* <Route
            path="photography-agency"
            element={<PhotographyAgencyHome />}
          /> */}
          {/* <Route path="digital-agency" element={<DigitalAgencyHome />} />
          <Route path="marketing-agency" element={<MarketingAgencyHome />} /> */}
          <Route exact path="about" element={<AboutPage />} />
          <Route exact path="service" element={<ServicesPage />} />
          {/* <Route
            path="service/:category/:title"
            element={<ServiceDetailsPage />}
          /> */}
          {/* <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          /> */}
          <Route exact path="blog" element={<BlogPage />} />
          <Route
            exact
            path="blog/:blogDetailsId"
            element={<BlogDetailsPage />}
          />
          <Route exact path="contact" element={<ContactPage />} />
          <Route exact path="team" element={<TeamPage />} />
          <Route exact path="team/:teamDetailsId" element={<TeamDetails />} />
          {/* <Route
            path="/case-study/:caseStudyDetailsId"
            
            element={<CaseStudyDetailsPage />}
          />
          <Route path="faq" element={<FaqPage />} /> */}
        </Route>

        <Route
          exact
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          {/* <Route
            path="creative-portfolio"
            element={<CreativePortfolioHome />}
          />
          <Route
            path="showcase-portfolio"
            element={<ShowcasePortfolioHome />}
          />
          <Route
            path="case-study-showcase"
            element={<CaseStudyShowcaseHome />}
          /> */}
        </Route>
        <Route exact path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
