import React ,{ useState , useEffect } from 'react'
import { Icon } from '@iconify/react';
import { useContactContentful } from '../../useContentful';

export default function ContactInfoWidget({withIcon, title}) {
  const [contact , setContact] = useState([]);

  const { getContactData } = useContactContentful();

  

  useEffect(() => {
    if (contact.length === 0) {
      getContactData().then((data) => data && setContact(data));
    }
  }, [getContactData, contact.length]);




  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      {
        contact 
        && contact.map((item, index) => (
          <ul className="cs-menu_widget cs-style1 cs-mp0" key={index}>
          <li>
            {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
           <a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>  | <a href={`tel:${item.phoneNumbers}`}>{item.phoneNumbers}</a>
          </li>
         
          <li>
            {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
            <a href={`mailto:${item.email}`}>{item.email}</a>
          </li>
          <li>
            {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
            {item.address}
          </li>
          <li>
            {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
            {item.address2}
          </li>
        </ul>
        ))
        }
    </>
  )
}
