import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// import { Link } from 'react-router-dom';
import { Icon } from "@iconify/react";
import parse from "html-react-parser";
import Div from "../Div";
import { useShowcaseData } from "../../useContentful";

export default function FullScreenHorizontalSlider() {
  const [showSliderData, setShowSliderData] = useState([]);
  const { getShowcaseData } = useShowcaseData();
  

  useEffect(() => {
    if (showSliderData && showSliderData.length === 0) {
      getShowcaseData().then((data) => data && setShowSliderData(data));
    }
  }, [getShowcaseData, showSliderData]);

  

  return (
    <Div className="cs-swiper_arrow_style_1">
      <Div className="swiper-button image-swiper-button-next">
        Next <Icon icon="bi:arrow-right" />
      </Div>
      <Div className="swiper-button image-swiper-button-prev">
        <Icon icon="bi:arrow-left" /> Prev
      </Div>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        speed={2000}
        autoplay={{
          delay: 1000,
        }}
        loop={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        navigation={{
          nextEl: ".image-swiper-button-next",
          prevEl: ".image-swiper-button-prev",
          disabledClass: "swiper-button-disabled",
        }}
      >
        {showSliderData.map((item, index) => (
          <SwiperSlide key={index}>
            <Div
              className="cs-hero cs-style4 cs-bg"
              style={{ backgroundImage: `url(${item.image})` }}
            >
              {/* <Link to={item.href} className="cs-hero_link" /> */}
              <Div className="cs-hero_text">
                <h2 className="cs-hero_title">{item.title}</h2>
              </Div>
            </Div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Div>
  );
}
