import { createClient } from "contentful";

const client = createClient({
  space: "ovotbk8xgfhu",
  accessToken: "ho6Q2iGJ4A_kKxK1MU6llPtpK5UxxFAcxOIYVSDN0_s",
});

const useBlogContentful = () => {
  const getBlogData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "blog",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          id: entry.fields.id,
          title: entry.fields.title,
          subject: entry.fields.subject,
          image: entry.fields.blogImage.fields.file.url,
          date: entry.fields.date,
          writtenBy: entry.fields.writtenBy,
          readingTime: entry.fields.readingTime,
          image1: entry.fields.blogImage1.fields.file.url,
          image2: entry.fields.blogImage2.fields.file.url,
          heading1: entry.fields.heading1,
          heading2: entry.fields.heading2,
          desc1: entry.fields.desc1,
          desc2: entry.fields.desc2,
          blackquote: entry.fields.blackquote,
          quoteby: entry.fields.quoteby,
        };
      });

      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getBlogData };
};

const useContactContentful = () => {
  const getContactData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "contact",
      });
      const sanitizdEntries = entries.items.map((entry) => {
        return {
          address: entry.fields.address,
          address2: entry.fields.adress2,
          email: entry.fields.email,
          phoneNumber: entry.fields.phoneNumber,
          phoneNumbers: entry.fields.phoneNumber2,
        };
      });

      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getContactData };
};

const useFaqContentful = () => {
  const getFaqData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "faq",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          question: entry.fields.question,
          answer: entry.fields.answer,
          url: entry.fields.url,
          category: entry.fields.category,
        };
      });

      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getFaqData };
};

const useAboutContentful = () => {
  const getAboutData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "about",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          title2: entry.fields.title2,
          content: entry.fields.content,
          mission: entry.fields.mission,
          vision: entry.fields.vision,
          image: entry.fields.contentImage.fields.file.url,
          image2: entry.fields.contentImage2.fields.file.url,
          image3: entry.fields.contentImage3.fields.file.url,
          image4: entry.fields.contentImage4.fields.file.url,
        };
      });

      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getAboutData };
};

const useFunFactContentful = () => {
  const getFunFactData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "funFact",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          factNumber: entry.fields.factNumber,
        };
      });

      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getFunFactData };
};

const useShowcaseData = () => {
  const getShowcaseData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "showCaseData",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          image: entry.fields.image.fields.file.url,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };
  return { getShowcaseData };
};

const useFunnyFactContentful = () => {
  const getFunnyFactData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "funnyFact",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          funnyThought: entry.fields.funnyThought,
        };
      });

      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getFunnyFactData };
};

const useHomePortfolioContentful = () => {
  const getHomePortfolioData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "homePorfolio",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          image: entry.fields.image.fields?.file.url,
          subTitle: entry.fields.subTitle,
          category: entry.fields.category,
          height: entry.fields.height,
          url: entry.fields?.url,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getHomePortfolioData };
};

const useHomeServiceCardContentful = () => {
  const getHomeServiceCardData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "homeServiceCard",
        order: "sys.createdAt",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          image: entry.fields.image.fields?.file.url,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getHomeServiceCardData };
};

const useHomeWorkForceContentful = () => {
  const getHomeWorkForceData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "homeWorkForce",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          image: entry.fields.image.fields?.file.url,
          subTitle: entry.fields.subTitle,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getHomeWorkForceData };
};

const useBrandLogoContentful = () => {
  const getBrandLogoData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "brandLogo",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          image: entry?.fields.image.fields?.file.url,
          url: entry?.fields?.url,
          title: entry?.fields?.title,
          shortInfo: entry?.fields?.shortInfo,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getBrandLogoData };
};

const useAboutHeroContentful = () => {
  const getAboutHeroData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "aboutHero",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          url: entry.fields.url,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getAboutHeroData };
};

const useTeamMemberContentful = () => {
  const getTeamMemberData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "teamMember",
        order: "sys.createdAt",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          memberName: entry.fields.memberName,
          memberImage: entry.fields.memberImage.fields?.file.url,
          memberDesignation: entry.fields.memberDesignation,
          memberDescription: entry.fields.description,
          linkedIn: entry.fields.linkedIn,
          instagram: entry.fields.instagram,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getTeamMemberData };
};

const useTestmonialContentful = () => {
  const getTestmonialData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "testimonial",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          image: entry.fields.image.fields?.file.url,
          text: entry.fields.text,
          name: entry.fields.name,
          designation: entry.fields.designation,
          rating: entry.fields.rating,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getTestmonialData };
};

const useServiceContentful = () => {
  const getServiceData = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "service",
        order: "sys.createdAt",
      });

      const sanitizdEntries = entries.items.map((entry) => {
        return {
          title: entry.fields.title,
          image: entry.fields.image.fields?.file.url,
          subTitle: entry.fields.subTitle,
          category: entry.fields.category,
          url: entry.fields?.url,
        };
      });
      return sanitizdEntries;
    } catch (error) {
      console.log(error);
    }
  };

  return { getServiceData };
};

export {
  useServiceContentful,
  useTestmonialContentful,
  useTeamMemberContentful,
  useBlogContentful,
  useContactContentful,
  useFaqContentful,
  useAboutContentful,
  useFunFactContentful,
  useShowcaseData,
  useAboutHeroContentful,
  useFunnyFactContentful,
  useHomePortfolioContentful,
  useHomeServiceCardContentful,
  useHomeWorkForceContentful,
  useBrandLogoContentful,
};
