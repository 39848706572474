import React, { useEffect , useRef } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import Div from "../Div";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import PostSlider from "../Slider/PostSlider";
import ServiceList from "../ServiceList";
import ServiceListRight from "../ServiceListRight";
import Hero5 from "../Hero/Hero5";
import VideoModal from "../VideoModal";
import { useLocation } from "react-router-dom";

export default function ServicesPage() {
  const scrollToElementRef = useRef(null);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  pageTitle("Service");
  useEffect(() => {
    window.scrollTo(0, 0);
    if(scrollToElementRef.current){
      scrollToElementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <>
      {/* Start Hero Section */}

      <Hero5
        title="Conquer Content Challenges<br /> with Exceptional Solutions"
        subtitle="At TLR, we pride ourselves on delivering top-notch solutions that make your<br /> brand shine.
         From brainstorming to the final visuals,
         we'll take care of every<br /> detail with finesse and flair.
         Get ready for a content experience that's out of this world!
        "
        btnLink="/contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
      />
      {/* End Hero Section */}

      {/* Start Video Block Section */}
      <Div className="cs-video_block_1_wrap">
        <Div className="container">
          <VideoModal
            videoSrc="https://www.youtube.com/watch?v=uJBHCGign7Q"
            bgUrl="https://ik.imagekit.io/tlredits/Service/cover.png?updatedAt=1686046757417"
          />
        </Div>
      </Div>
      {/* End Video Block Section */}

      <Spacing lg="150" md="80" />
      <Div className="container" id="videography" ref={scrollToElementRef}>
        <SectionHeading
          title="Creative Editing Wizardry"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <ServiceListRight category="Creative Editing Wizardry" />
      </Div>

      <Spacing lg="80" md="80" />
      <Div className="container" ref={scrollToElementRef} id="photography">
        <SectionHeading
          title="Magic through our lens"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <ServiceList category="Magic through our lens" />
      </Div>

      <Spacing lg="80" md="80" />
      <Div className="container" ref={scrollToElementRef} id="animation">
        <SectionHeading
          title="YouTube Video Editing"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <ServiceListRight category="YouTube Video Editing" />
      </Div>

      <Spacing lg="80" md="80" />
      <Div className="container" ref={scrollToElementRef} id="graphicDesign">
        <SectionHeading
          title="Graphic Design Sorcery"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <ServiceList category="Graphic Design Sorcery" />
      </Div>

      {/* End Portfolio Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services"
                subtitle="Our Best Services"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
