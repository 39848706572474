import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';

export default function ContactPage() {
  const [user, setUser] = useState({
    name: '',
    email: '',
    subject: '',
    mobile: '',
    message: '',
  });

  let name, value;
  const postUserData = async (event) => {
    name = event.target.name;
    value = event.target.value;
    setUser({ ...user, [name]: value });
  };

  const submitData = async (event) => {
    event.preventDefault();
    const { name, email, subject, mobile, message } = user;

    if (name && email && subject && mobile && message) {
      const res = await fetch('https://tlr-edits-default-rtdb.firebaseio.com/userData.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: user.name,
          email: user.email,
          subject: user.subject,
          mobile: user.mobile,
          message: user.message,
        }),
      });

      if (res) {
        setUser({
          name: '',
          email: '',
          subject: '',
          mobile: '',
          message: '',
        });
        alert('Data Stored');
      }
    } else {
      alert('Please fill the data');
    }
  };



  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact.jpg"
        pageLinkText="Contact"
        subtitle=""
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Ready to Bring Your<br/> Vision to Life?"
              subtitle="Let's Connect and Explore Project Possibilities!"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" method='POST'>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Name*</label>
                <input type="text" className="cs-form_field" placeholder='Name' value={user.name} onChange={postUserData} name='name' />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="text" className="cs-form_field" placeholder='Email' value={user.email} onChange={postUserData} name='email' />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Subject*</label>
                <input type="text" className="cs-form_field" placeholder='Subject' value={user.subject} onChange={postUserData} name='subject' />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" className="cs-form_field" placeholder='Mobile' value={user.mobile} onChange={postUserData} name='mobile' />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="7"
                  placeholder='Message'
                  className="cs-form_field"
                  value={user.message}
                  onChange={postUserData}
                  name='message'
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" onClick={submitData}>
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3575.7615593022624!2d73.04966737602366!3d26.334219384710437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418c3245d40001%3A0x720b23df767e050d!2sThe%20Lecture%20Room!5e0!3m2!1sen!2sin!4v1682448021359!5m2!1sen!2sin"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}