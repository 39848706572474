import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "./pricing.scss";
import Button from "../Button";
import Div from "../Div";

export default function PricingTable({
  title,
  price,
  currency,
  features,
  btnLink,
  btnText,
  timeline,
}) {
  return (
    <Div className="cs-pricing_table cs-style1">
      <h2 className="cs-pricing_title">{title}</h2>
      <Div className="cs-pricing_info">
        <Div className="cs-price">
          <h3 className="cs-white_color">
            {currency} {price}{" "}
            <span className="cs-white_color cs-fontsize"> excl. GST</span>/
          </h3>
          <span className="cs-accent_color">{timeline}</span>
          {/* <Div className="row cs-active">
            <Div className="col-6">
              <Div
                onClick={() => handleBillingTab("monthly")}
                className={` ${billingTab === "monthly" ? "active" : ""}`}
              >
                Monthly
              </Div>
            </Div>
            <Div className="col-6">
              <Div
                onClick={() => handleBillingTab("yearly")}
                className={` ${billingTab === "yearly" ? "active" : ""}`}
              >
                Yearly
              </Div>
            </Div>
          </Div> */}
        </Div>
      </Div>
      <ul className="cs-pricing_feature cs-mp0">
        {features.map((feature, index) => (
          <li key={index}>
            <span className="cs-feature_icon cs-accent_color">
              <Icon icon="bi:arrow-right-circle" />
            </span>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <Div className="cs-pricing_btn_wrap">
        <Button btnLink={btnLink} btnText={btnText} />
      </Div>
    </Div>
  );
}
