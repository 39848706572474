import React, { useState , useEffect } from 'react';
import Slider from 'react-slick';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
import { useTestmonialContentful } from '../../useContentful';

export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [testimonialData, setTestimonialData] = useState([]);

  const { getTestmonialData } = useTestmonialContentful();

  useEffect(() => {
    if (testimonialData.length === 0) {
      getTestmonialData().then((data) => {
        setTestimonialData(data);
      });
    }
  }, [getTestmonialData, testimonialData.length]);



  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => {};
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => {};
  return (
    <>
       <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.image} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.text}
                      avatarName={item.name}
                      avatarDesignation={item.designation}
                      ratings={item.rating}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div> 
    </>
  );
}
