import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import PostStyle2 from "../Post/PostStyle2";
import Div from "../Div";
import Spacing from "../Spacing";
import { useBlogContentful } from "../../useContentful";
import { Link } from "react-router-dom";

export default function BlogPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const [blogs, setBlogs] = useState([]);
  const { getBlogData } = useBlogContentful();

  useEffect(() => {
    if (blogs && blogs.length === 0) {
      getBlogData().then((data) => data && setBlogs(data));
    }
  }, [getBlogData, blogs]);

  pageTitle("Blog");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dataNumber = [];
  for (let i = 1; i <= Math.ceil(blogs.length / postsPerPage); i++) {
    dataNumber.push(i);
  }

  const currentPageData = () => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);
    return currentPosts;
  };

  const paginateNext = () => {
    if (currentPage < blogs.length / postsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const paginatePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const onButtonClick = (type) => {
    if (type === "prev") {
      paginatePrev();
    } else if (type === "next") {
      paginateNext();
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <PageHeading
        title="Insights Unleashed"
        bgSrc="/images/hero_bg.jpg"
        pageLinkText="Blog"
        subtitle="With our blogs full of knowledge, Perspectives, and<br /> Inspiration for Content Creators and Business Growth."
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          {currentPageData().map((blog, blogIndex) => (
            <Div className="col-lg-6">
              <PostStyle2 blog={blog} key={blogIndex} href={`${blogIndex}`} />
              <Spacing lg="60" md="40" />
              {currentPage.length > blogIndex + 1 && (
                <Spacing lg="95" md="60" />
              )}
            </Div>
          ))}
          <Spacing lg="60" md="40" />

          <ul className="cs-pagination_box cs-center cs-white_color cs-mp0 cs-semi_bold">
            <li>
              <Link
                onClick={() => onButtonClick("prev")}
              >
                <p className="cs-pagination_item cs-center">Prev</p>

                 {/* <Icon icon="akar-icons:chevron-left" />  */}
              </Link>
            </li>
         

            <li>
              <Link
                onClick={() => onButtonClick("next")}
              >

                <p className="cs-pagination_item cs-center">Next</p>
                {/* <Icon icon="akar-icons:chevron-right" /> */}
              </Link>
            </li>
          </ul>

          {/* <Pagination/> */}
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
