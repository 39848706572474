import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import Div from '../Div'
import Spacing from '../Spacing'
import { useTeamMemberContentful } from '../../useContentful'
import { useParams } from 'react-router-dom'
import { Icon } from '@iconify/react';

export default function TeamDetails() {
  pageTitle('Team Member');




  const [team, setTeam] = useState([]);
  const { getTeamMemberData } = useTeamMemberContentful();


  useEffect(() => {
    if (team.length === 0) {
      getTeamMemberData().then((data) => {
        setTeam(data);
      });
    }
  }, [getTeamMemberData, team.length]);

  const params = useParams();



  const teamMember = team[params.teamDetailsId] || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <PageHeading
        title='Team Details'
        bgSrc='/images/team_hero_bg.jpeg'
        pageLinkText='Team Details'
      /> */}
       <Spacing lg='150' md='80' />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src={teamMember?.memberImage} alt="Member" className="w-100" />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <Spacing lg='0' md='45' />
            <Div className="cs-section_heading cs-style1">
              <h2 className="cs-section_title">{teamMember?.memberName}</h2>
              <Div className="cs-height_10 cs-height_lg_10" />
              <h3 className="cs-section_subtitle">{teamMember?.memberDesignation}</h3>
              <Div className="cs-height_5 cs-height_lg_5" />
              <Div className="cs-separator cs-accent_bg" />
              <Div className="cs-height_45 cs-height_lg_25" />
              <p className="cs-m0">
                {teamMember?.memberDescription}
              </p>
              <Div className="cs-height_25 cs-height_lg_20" />
              {/* <p className="cs-m0">Ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.</p> */}
              <Div className="cs-height_45 cs-height_lg_30" />
              <Div className="cs-social_btns cs-style1">
                <a href={teamMember?.linkedIn} className="cs-center" target='_blank' rel="noreferrer">
                  <Icon icon="fa6-brands:linkedin-in" />
                </a>
                <a href='https://www.facebook.com/thelectureroomjodhpur' className="cs-center" target='_blank' rel="noreferrer">
                  <Icon icon="fa6-brands:facebook" />
                </a>
                <a href={teamMember?.instagram} className="cs-center" target='_blank' rel="noreferrer">
                  <Icon icon="fa6-brands:instagram" />
                </a>
                <a href='https://www.youtube.com/@thelectureroom9195' className="cs-center" target='_blank' rel="noreferrer">
                  <Icon icon="fa6-brands:youtube" />
                </a>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Let’s disscuse make <br />something <i>cool</i> together'
          btnText='Apply For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
