import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react';
import './team.scss'
import { Link } from 'react-router-dom';
import Div from '../Div';
import { useTeamMemberContentful } from '../../useContentful';

export default function Team({ memberImage, memberName, memberDesignation, linkedIn, instagram , teamIndex }) {
  const [linkData, setLinkData] = useState([]);

  const { getTeamMemberData } = useTeamMemberContentful();

  useEffect(() => {
    if (linkData.length === 0) {
      getTeamMemberData().then((data) => {
        setLinkData(data);
      });
    }
  }, [getTeamMemberData, linkData.length]);



  return (
    <Div className="cs-team cs-style1">
    <Div className="cs-member_thumb">
          <img src={memberImage} alt={memberName} />
          <Div className="cs-member_overlay" />
        </Div>
        <Div className="cs-member_info">
        <Link to={`/team/${teamIndex}`}>
          <h2 className="cs-member_name">{memberName}</h2>
          <Div className="cs-member_designation">{memberDesignation}</Div>
          </Link>
        </Div>
        
        <Div className="cs-member_social cs-primary_color">
        <a href={linkedIn} className="cs-center" target='_blank' rel="noreferrer">
                <Icon icon="fa6-brands:linkedin-in" />
              </a>
              <a href={instagram} className="cs-center" target='_blank' rel="noreferrer">
                <Icon icon="fa6-brands:instagram" />
              </a>
        </Div>
    </Div>
  )
}
