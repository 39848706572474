import React, { useEffect, useState } from "react";
import { pageTitle } from "../../helper";
import "swiper/css";
import "swiper/css/pagination";
import Hero6 from "../Hero/Hero6";
import Card from "../Card";
import FunFact from "../FunFact";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";
import LogoList from "../LogoList";
import MovingText from "../MovingText";
import MasonryGallery from "../Gallery/MasonryGallery";
import Portfolio from "../Portfolio";
import PricingTableList from "../PricingTable/PricingTableList";
import {
  useFunnyFactContentful,
  useHomePortfolioContentful,
  useHomeServiceCardContentful,
  useHomeWorkForceContentful,
  useBrandLogoContentful,
} from "../../useContentful";
import { Link } from "react-router-dom";
import PostSlider from "../Slider/PostSlider";

export default function ShowcasePortfolioHome() {
  const [funnyFact, setFunnyFact] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [serviceCardData, setServiceCardData] = useState([]);
  const [workForceData, setWorkForceData] = useState([]);

  const { getFunnyFactData } = useFunnyFactContentful();
  const { getHomePortfolioData } = useHomePortfolioContentful();
  const { getHomeServiceCardData } = useHomeServiceCardContentful();
  const { getHomeWorkForceData } = useHomeWorkForceContentful();

  const targetElementId = [
    "videography",
    "photography",
    "animation",
    "graphicDesign",
  ];

  pageTitle("Home");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (workForceData.length === 0) {
    getHomeWorkForceData().then((data) => data && setWorkForceData(data));
  }

  if (funnyFact.length === 0) {
    getFunnyFactData().then((data) => data && setFunnyFact(data));
  }

  if (portfolioData.length === 0) {
    getHomePortfolioData().then((data) => data && setPortfolioData(data));
  }

  if (serviceCardData.length === 0) {
    getHomeServiceCardData().then((data) => data && setServiceCardData(data));
  }

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: "Youtube",
      links: "https://www.youtube.com/@thelectureroom9195",
    },
    {
      name: "Linkedin",
      links: "https://www.linkedin.com/company/thelectureroom/",
    },
  ];

  return (
    <>
      <Hero6
        heroSocialLinks={heroSocialLinks}
        socialLinksHeading="Follow Us"
        scrollDownId="#service"
      />
      <Spacing lg="100" md="150" />

      {/* Start FunFact Section */}
      <div className="container">
        {funnyFact.map((item, index) => (
          <FunFact
            key={index}
            variant="cs-type1"
            title={item.title}
            subtitle={item.funnyThought}
          />
        ))}
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="End-to-End Services for all your content needs"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>

            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title={serviceCardData[0]?.title}
                    link={`service/#${targetElementId[0]}`}
                    src={serviceCardData[0]?.image}
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title={serviceCardData[1]?.title}
                    link={`service/#${targetElementId[1]}`}
                    src={serviceCardData[1]?.image}
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title={serviceCardData[2]?.title}
                    link={`service/#${targetElementId[2]}`}
                    src={serviceCardData[2]?.image}
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title={serviceCardData[3]?.title}
                    link={`service/#${targetElementId[3]}`}
                    src={serviceCardData[3]?.image}
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      {/* End Service Section */}

      <Spacing lg="150" md="80" />

      <Div className="container">
        <Div className="row">
          <SectionHeading
            title="Where Startups Thrive and Creativity Shines"
            subtitle="We work with"
          />
          <Spacing lg="30" md="20" />
          {workForceData?.map((item, index) => (
            <Div className="col-lg-6" key={index}>
              <Portfolio
                src={item?.image}
                title={item?.title}
                subtitle={item?.subTitle}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="0" md="30" />
            </Div>
          ))}
        </Div>
      </Div>

      <Spacing lg="125" md="70" />

      <Div className="container">
        <SectionHeading
          title="Providing best <br/>pricing for client"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="85" md="40" />
        <PricingTableList />
      </Div>
      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Our reputed world wide clients" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <Div className="row">
          <PostSlider />
        </Div>
      </Div>
      {/* End LogoList Section */}

      {/* Start Portfolio Section */}
      <Spacing lg="145" md="80" />
      <MasonryGallery />

      <Spacing lg="150" md="50" />
      {/* End Portfolio Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Don't Be Shy, Say Hi!"
          btnText="Contact us and let's craft something extraordinary"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
